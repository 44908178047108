// src/components/Personnalisation.js
import React, { useContext, useState, useEffect } from "react";
import { OperationContext } from "../operationContext";
import PLVSkeleton from "./skeleton/PLVSkeleton";

const Personnalisation = () => {
  const {
    formState,
    handlePersonnalisationChoixChange,
    handleInputChange,
    isLoading,
  } = useContext(OperationContext);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (
      formState.mo_courrier_personnalisation === "1" &&
      !formState.mo_courrier_personnalisation_txt
    ) {
      setErrorMessage(
        "Veuillez entrer un commentaire pour la personnalisation."
      );
    } else {
      setErrorMessage("");
    }
  }, [
    formState.mo_courrier_personnalisation,
    formState.mo_courrier_personnalisation_txt,
  ]);

  if (isLoading) {
    return <PLVSkeleton />;
  }
  const isDisabled = formState.id_mag_cmd > 0;

  return (
    <div className="block medium personnalisation">
      <h3>Personnalisation</h3>
      <h5>
        (autre ciblage, changement dates de validité, mention particulière,
        logo…)
      </h5>
      <div className="selection-container">
        <div className="left-selection">
          <div className="form-group">
            <label htmlFor="personnalisation-choix">Mon choix :</label>
          </div>
        </div>
        <div className="right-selection">
          <div className="form-group">
            <select
              id="personnalisation-choix"
              className="styled-select"
              name="mo_courrier_personnalisation"
              value={formState.mo_courrier_personnalisation}
              onChange={(e) =>
                handlePersonnalisationChoixChange(e.target.value)
              }
              disabled={isDisabled}
            >
              <option value="0">Non</option>
              <option value="1">Oui</option>
            </select>
          </div>
        </div>
      </div>
      {formState.mo_courrier_personnalisation === "1" && (
        <div className="footer-section">
          <textarea
            id="specific-cover"
            name="mo_courrier_personnalisation_txt"
            value={formState.mo_courrier_personnalisation_txt}
            onChange={handleInputChange}
            placeholder="Entrez la description de la personnalisation..."
            disabled={isDisabled}
          />
          {errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default Personnalisation;
