import React, { useContext } from "react";
import { OperationContext } from "../operationContext";
import { formatPrix } from "../../../../../utils/dates";
import BudgetSkeleton from "./skeleton/budgetSkeleton";

const budgetColumnsMap = {
  1: ["Opération", "Distribution", "PLV", "Bonial", "Relais", "TOTAL"],
  2: ["Carnet", "SMS", "SMS Location BDD", "PLV", "TOTAL"],
  3: ["Opération", "SMS fidélité", "SMS Location BDD", "TOTAL"],
  4: ["Opération", "TOTAL"],
  5: ["Courrier", "Opération", "SMS fidélité", "SMS Location BDD", "TOTAL"],
};

const budgetFieldsMap = {
  Opération: "mo_mnt_operation",
  Distribution: "mo_mnt_dist",
  PLV: "mo_mnt_option",
  Bonial: "mo_mnt_bonial",
  Relais: (budgetData) => budgetData.mo_mnt_sms + budgetData.mo_mnt_loc_sms,
  TOTAL: "mo_mnt_total",
  SMS: "mo_mnt_sms",
  "SMS fidélité": "mo_mnt_sms",
  "SMS Location BDD": "mo_mnt_loc_sms",
  Courrier: (budgetData) =>
    budgetData.mo_mnt_courrier +
    budgetData.mo_mnt_couv_nb +
    budgetData.mo_mnt_web,
  Carnet: (budgetData) =>
    budgetData.mo_mnt_courrier +
    budgetData.mo_mnt_couv_nb +
    budgetData.mo_mnt_web,
};

const isFieldEmpty = (field, budgetData) => {
  const value =
    typeof field === "function" ? field(budgetData) : budgetData[field];
  return value === null || value === undefined || value === "" || value === 0;
};

const Budget = ({ setShowErrors }) => {
  const {
    budgetData,
    operationsData,
    isLoading,
    handleDeleteMagOpe,
    handleSaveMagOpe,
    resultMagOpe,
    formState,
  } = useContext(OperationContext);

  const handleDelete = () => {
    handleDeleteMagOpe(operationsData.id_operation);
  };

  const handleSave = () => {
    if (resultMagOpe?.invalidFields && resultMagOpe.invalidFields.length > 0) {
      setShowErrors(true);
    } else {
      handleSaveMagOpe();
    }
  };
  // Récupérer les colonnes à afficher en fonction de id_ope_type
  const columnsToRender = (
    budgetColumnsMap[operationsData.id_ope_type] || []
  ).filter((col) => !isFieldEmpty(budgetFieldsMap[col], budgetData));

  if (!budgetData || !operationsData.id_ope_type) {
    return null;
  }
  if (isLoading) {
    return <BudgetSkeleton />;
  }
  const isDisabled = formState.id_mag_cmd > 0;

  return (
    <div className="block large budget">
      <h3>Budget</h3>
      <div className="table-budget">
        <table className="budget-table">
          <thead>
            <tr>
              {columnsToRender.map((col) => (
                <th key={col}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {columnsToRender.map((col) => {
                const field = budgetFieldsMap[col];
                const value =
                  typeof field === "function"
                    ? field(budgetData)
                    : budgetData[field];
                return <td key={col}>{formatPrix(value)}</td>;
              })}
            </tr>
          </tbody>
        </table>
        {!isDisabled && (
          <div className="budget-button-group">
            <button className="budget-button budget-add" onClick={handleSave}>
              Enregistrer
            </button>
            <button
              className="budget-button budget-delete"
              onClick={handleDelete}
            >
              Annuler
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Budget;
