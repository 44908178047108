import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useAuth from '../../../../../hooks/useAuth';
import { useParams } from 'react-router-dom';
import { exportArticlesToExcelFile, getContratsByMetier } from '../../../../../services/fournisseurs_produits_apis';
import NormalTable from '../../NormalTable';
import Select from '../../../input/Select';
import Input from '../../../input/Input';
import { columns } from './columns';
import Button from '../../../button/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchArticles,
  pageNext,
  pagePrev,
  resetPage,
  setFiltres,
  resetFiltres,
} from '../../../../../features/articleSlice';
import './style.scss';
import moment from 'moment';
import LoaderComponent from '../../../loader/LoaderComponent';
import XlsIcon from '../../../icons/XlsIcon';

const ArticleTable = () => {
  const { magasin, user } = useAuth(); // Obtenir l'objet magasin à partir du hook useAuth
  const { con_code } = useParams(); // Obtenir la valeur con_code à partir du hook useParams
  const [contratsList, setContratsList] = useState([]); // État pour la liste des contrats

  const dispatch = useDispatch(); // Obtenir la fonction dispatch à partir du hook useDispatch
  const { articles, loading, filtres, page, last_page } = useSelector(
    (state) => state.article
  ); // Obtenir les valeurs nécessaires à partir du state global
  const filtersRef = useRef({}); // reference a filtre pour ne pas mettre a jour les données a chaque modification du filtre

  // Fonction de gestion du changement de filtre
  const handleChangeFilter = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    dispatch(setFiltres({ ...filtersRef.current, [name]: value }));
  };

  useEffect(() => {
    filtersRef.current = filtres;
  }, [filtres]);

  // Fonction pour rafraîchir les données en appelant fetchArticles
  const refreshData = useCallback(() => {
    dispatch(
      fetchArticles({
        mag_code: magasin.mag_code,
        filtres: filtersRef.current,
        page: page,
      })
    );
  }, [dispatch, magasin.mag_code, page]);

  // Fonction pour récupérer les contrats par métier
  const fetchContrats = useCallback(async () => {
    try {
      const data = await getContratsByMetier(
        magasin.mag_code,
        0 // on veut tous les contrats
      );
      setContratsList(data); // Mettre à jour la liste des contrats avec les données récupérées
      dispatch(
        setFiltres({ ...filtersRef.current, con_code: con_code })
      );
    } catch (err) {
      console.log(err);
    }
  }, [con_code, dispatch, magasin.mag_code]);

  // Initialisation des données au montage du composant
  React.useEffect(() => {
    fetchContrats();
  }, [fetchContrats]);

  React.useEffect(() => {
    const initialize = async () => {
      dispatch(resetFiltres());
      await fetchContrats();
      refreshData();
    };
    initialize();
  }, []);

  // // Initialisation des données d'articles
  // React.useEffect(() => {
  //   const initialize = async () => {
  //     if (loading) {
  //       await fetchContrats();
  //       refreshData();
  //     }
  //   };
  //   initialize();
  // }, [dispatch, fetchContrats, loading, refreshData]);

  // Fonction appelée lors du clic sur le bouton de recherche
  const clickRecherche = () => {
    refreshData();
  };

  const [displayDownloadButton, setDisplayDonwloadButton] = useState(false);

  // Function qui regarde la présence de donnée dans les filtres et modifier le state d'affichage du bouton
  const toggleDownloadButton = () => {
    let notNullFilterCount = 0

    Object.keys(filtres).map(filtre => {
        if(filtre == "con_code"){
          if(filtres[filtre]) {
            if(filtres[filtre] != 0) {
              notNullFilterCount += 1
            }
          }
        } else {
          if(filtres[filtre]){
            console.log('in if',filtre)
            notNullFilterCount += 1
          }
        }
    })

    if(notNullFilterCount > 0) {
      setDisplayDonwloadButton(true)
    } else {
      setDisplayDonwloadButton(false)
    }
  }

  // UseEffect pour metre a jour l'affichage du bouton en écoutant les filtres
  useEffect(() => {
    toggleDownloadButton()
  }, [filtres])

  /**
   * Function pour télécharger sous un fichier Xls les articles en fonction des filtres
   */
  const downloadExcelFile = async () => {
    const data = filtersRef.current
    try {
      const response = await exportArticlesToExcelFile(magasin.mag_code , data)
      console.log(response);
    
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      window.open(url)
      
    } catch (error) {
      console.error('Erreur lors de la récupération du fichier :',error)
    }
  }

  console.log('USER : ' , user)

  // Appeler l'action fetchArticles a chaque mise à jour
  React.useEffect(() => {
    refreshData();
  }, [page, refreshData]);

  const columnsMemo = useMemo(() => columns, []);
  const dataMemo = useMemo(() => articles, [articles]);

  const currentWeek = moment().week();
  const week1 = currentWeek - 1;
  const week2 = currentWeek - 2;
  const week3 = currentWeek - 3;
  return (
    <div className="articles-table">
      <div className="articles-filtre">
        <div>
          <div className="duo-filters">
            <Select
              label="Fournisseur"
              name="con_code"
              value={filtres.con_code}
              onChange={(event) => {
                handleChangeFilter(event);
              }}
            >
              <option value="">Tous les Fournisseurs</option>
              {contratsList.map((contrat) => (
                <option
                  key={contrat.con_code}
                  value={contrat.con_code}
                >
                  {contrat.con_nbart === 0
                    ? `${contrat.con_nom} (pas d'articles)`
                    : contrat.con_nom}
                </option>
              ))}
            </Select>

            <Select
              label="Préconisation"
              onChange={(event) => handleChangeFilter(event)}
              name="ac_code"
              value={filtres.ac_code}
            >
              <option key="ac_code1" value="">
                Tous les articles
              </option>
              <option key="ac_code2" value="2">
                Articles préconisés
              </option>
            </Select>
          </div>

          <div className="duo-filters">
            <Input
              label="Désignation"
              name="art_des"
              placeholder=" "
              value={filtres.art_des}
              onChange={(event) => handleChangeFilter(event)}
            />
            <Input
              label="Marque"
              name="art_marque"
              placeholder=" "
              value={filtres.art_marque}
              onChange={(event) => handleChangeFilter(event)}
            />
            <Select
              label="Mises à jour"
              onChange={(event) => handleChangeFilter(event)}
              name="art_maj"
              value={filtres.art_maj}
            >
              <option key="0" value="0">
                -
              </option>
              <option key="1" value="1">
                Semaine {week1}
              </option>
              <option key="2" value="2">
                Semaine {week2}
              </option>
              <option key="3" value="3">
                Semaine {week3}
              </option>
            </Select>
          </div>
        </div>

        <div className="horizontal-filters">
          <Input
            label="Gencod"
            name="art_gencod"
            placeholder=" "
            value={filtres.art_gencod}
            onChange={(event) => handleChangeFilter(event)}
          />

          <Input
            label="Référence"
            name="art_reference"
            placeholder=" "
            value={filtres.art_reference}
            onChange={(event) => handleChangeFilter(event)}
          />
          <Select
            label="Nouveautés"
            name="art_nouveaute"
            onChange={(event) => handleChangeFilter(event)}
            value={filtres.art_nouveaute}
          >
            <option key="art_nouveaute1" value="">
              Tous
            </option>
            <option key="art_nouveaute2" value="-1">
              Nouveautés
            </option>
          </Select>
          <div className="filter-search-btn">
            <Button
              libelle="Rechercher"
              onClick={() => clickRecherche()}
            />
            {/* Affichage si l'utilisateur a le role admin 1 ou inedis 3 */}
            {
              displayDownloadButton && user && (user.roles[0].id == 1 || user.roles[0].id == 3) &&
              <Button
                libelle={<>Exporter la liste <br></br> (Réservé inedis)</>}
                icon={<XlsIcon/>}
                onClick={() => downloadExcelFile()}
              />
            }
          
          </div>
        </div>
      </div>

      {!loading ? (
        <>
          <NormalTable
            columns={columnsMemo}
            data={dataMemo}
            globalFilter={false}
            hideGlobalFilter
          />

          <div className="pagination">
            <button
              onClick={() => {
                dispatch(resetPage());
              }}
            >
              {'<<'}
            </button>
            <button
              onClick={() => {
                dispatch(pagePrev());
              }}
            >
              {'<'}
            </button>
            <span>
              Page {page}&nbsp;/{last_page}
            </span>
            <button
              onClick={() => {
                dispatch(pageNext());
              }}
            >
              {'>'}
            </button>
          </div>
        </>
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};

export default ArticleTable;
